<template>
  <main>
    <Row :gutter="15">
      <Col :span="10">
        <ButtonGroup shape="circle">
          <Button icon="md-refresh" @click="loadData">刷新</Button>
        </ButtonGroup>
      </Col>
      <Col :span="7" class="text-right">
        <DatePicker
          type="daterange"
          placeholder="激活时间范围"
          @on-change="filterDate"
          style="width: 250px"
        ></DatePicker>
      </Col>
      <Col :span="7">
        <searcher ref="searcher" :options="searches" @on-change="loadData"></searcher>
      </Col>
    </Row>

    <Divider/>

    <Table border :columns="columns" :data="data" @on-sort-change="changeSort"></Table>

    <Divider/>
    <pagination ref="pagination" :total="total" @on-change="loadData"/>

   
  </main>
</template>

<script>

export default {
  data() {
    return {
     
      searches: {
        user_id: "用户ID",
        number:'订单号',
        member_card_online_id:'会员卡ID'
      },
      sort: "",
      total: 0,
      data: [],
      columns: [
        {
          title: "ID",
          key: "id",
          sortable: "custom",
          align: "center",
          minWidth: 80
        },
        {
          title: "会员卡标题",
          key: "card",
          sortable: "custom",
          align: "center",
          minWidth: 130,
          render: (h, params) => {
            return h("p", params.row.card.title);
          }
        },
        {
          title: "激活用户",
          key: "user",
          sortable: "custom",
          align: "center",
          minWidth: 200,
          render: (h, params) => {
            return h(
              "a",
              {
                class: "flex flex-align-center",
                on: {
                  click: () => {
                    this.$router.push("/user/detail/" + params.row.user.id);
                  }
                }
              },
              [
                h("img", {
                  attrs: {
                    src: params.row.user.avatar
                      ? params.row.user.avatar
                      : require("@/assets/no-avatar.jpg"),
                    width:'48',
                    height:'48'
                  },
                  class: "avatar mr"
                }),
                h("p", params.row.user.nickname)
              ]
            );
          }
        },
        {
          title: "数量",
          key: "quantity",
          sortable: "custom",
          align: "center",
          minWidth: 80
        },
        {
          title: "金额",
          minWidth: 120,
          key: "pay_fee",
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            return h(
              "p",
              { class: "text-price" },
              params.row.pay_fee
            );
          }
        },
        {
          title: "支付订单号",
          key: "number",
          sortable: "custom",
          align: "center",
          minWidth: 80
        },
        {
          title: "订单状态",
          key: "status",
          sortable: "custom",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            switch (params.row.status) {
              case 0:
                return h("Tag", { props: { color: "orange" } }, "取消支付");

              case 1:
                return h("Tag", { props: { color: "success" } }, "支付成功");

              default:
                return h("Tag", "-");
            }
          }
        },
        {
          title: "支付时间",
          minWidth: 140,
          key: "created_at",
          align: "center",
          sortable: "custom"
        },
        
      ]
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      let params = {
        
        sort: this.sort,
        search_key: this.$refs.searcher.search_key,
        keywords: this.$refs.searcher.keywords,
        begin_date: this.begin_date,
        end_date: this.end_date,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit
      };

      this.$http.get("/memberCardOnlineRecords/list", { params: params }).then(res => {
        this.total = res.total;
        this.data = res.data;
      });
    },
    filterDate(date) {
      this.begin_date = date[0];
      this.end_date = date[1];
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    changeSort(item) {
      this.sort = item.order == "normal" ? "" : item.key + "," + item.order;
      this.$refs.pagination.page = 1;
      this.loadData();
    },
   
  }
};
</script>